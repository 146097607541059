import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

const ReferencePage = ({ data }) => {
    const references = data.allMdx.edges

    return (
        <Layout pageTitle="References">
            <div className="px-5">
                <h2>References</h2>
                <ul className="list-group border-0">
                    {
                        references.map(({ node, idx }) => (
                            <li className="list-group-item border-0" key={node.id}>
                                <Link to={`/references/${node.slug}`}>
                                    {node.frontmatter.title}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        allMdx(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: {fileAbsolutePath: {regex: "\\/references/"}}
        ) {
            edges {
                node {
                    id
                    slug
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`

export default ReferencePage
